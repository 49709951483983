import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ConfigService } from './config.service';
import { User } from '../model/user.model';


@Injectable({ providedIn: 'root' })
export class AuthenticationService {

    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    constructor(private http: HttpClient, private config: ConfigService) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }


    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    loginViaUsernameAndPassword(login: string, password: string): Observable<User> {

        return this.http.post(this.config.baseUrl + `api/auth/login`, { login, password }, { responseType: 'text'})
        .pipe(map(token => {
            const user = new User();
            user.token = token;
            return user;
          }));
          
        // return this.http.get(this.config.baseUrl + 'api/login/dummy', {responseType: 'text'}).pipe(map(token => {
        //   const user = new User();
        //   user.token = token;
        //   return user;
        // }));
    }


    persistToken(token: string): void {
      const user = new User();
      user.token = token;
      localStorage.setItem('currentUser', JSON.stringify(user));
      this.currentUserSubject.next(user);
    }

    logout(): void {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
    }

}
