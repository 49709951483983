import { Injectable } from "@angular/core";
import { ConfigService } from "./config.service";
import { HttpClient } from "@angular/common/http";
import { FinanceOverview } from "../model/finance-overview.model";
import { TimeFrameFilter } from "../model/timeframe-filter.model";
import { Observable } from "rxjs";


@Injectable({providedIn: 'root'})
export class FinanceOverviewService {

  constructor(private http: HttpClient, private config: ConfigService) { }


  getFinanceOverview(timeframe: TimeFrameFilter): Observable<FinanceOverview> {    
        return this.http.post<FinanceOverview>(this.config.baseUrl + 'api/finance/overview/byTimeFrame', timeframe);
  }

}

