<div class="container-fluid">
  <div class="row">
    <div class="col karta m-4">
      <ul class="generic-list m-4">
        <li>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Tenetur, temporibus.</li>
        <li>Ad exercitationem vel quis minima eaque! Perferendis temporibus doloribus dignissimos!</li>
        <li>Deleniti ad tempora culpa incidunt sint dicta, fugiat pariatur minus.</li>
        <li>Asperiores iste obcaecati unde fugiat dolorem doloremque voluptas, excepturi hic.</li>
        <li>Molestias saepe pariatur aperiam amet at inventore velit, quibusdam optio.</li>
      </ul>
    </div>
    <div class="col karta m-4">
      <ul class="generic-list m-4">
        <li>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Tenetur, temporibus.</li>
        <li>Ad exercitationem vel quis minima eaque! Perferendis temporibus doloribus dignissimos!</li>
        <li>Deleniti ad tempora culpa incidunt sint dicta, fugiat pariatur minus.</li>
      </ul>
    </div>

    <div class="col karta m-4">
      <ul class="generic-list m-4">
        <li>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eos, voluptatum.</li>
        <li>Delectus hic vero velit tempore deserunt impedit fuga, officiis praesentium.</li>
        <li>Atque enim voluptatem perspiciatis aspernatur omnis quaerat consectetur laborum cum.</li>
        <li>Facilis iusto vitae sequi maxime quod dignissimos et quidem atque!</li>
        <li>Quaerat cum voluptate molestias ducimus doloremque consectetur! In, soluta magnam.</li>
        <li>Magnam, molestiae. Fuga dolorem vel temporibus laudantium nostrum inventore magni.</li>
        <li>Aperiam earum ad iure impedit voluptate accusantium eos quibusdam. Fugiat.</li>
        <li>Fugiat perspiciatis expedita ipsum dolor atque adipisci enim, ratione magnam.</li>
        <li>Veniam pariatur nemo veritatis mollitia! Odio aperiam aspernatur nam! Suscipit!</li>
        <li>Obcaecati ducimus voluptates asperiores veniam soluta reprehenderit sed vitae sint.</li>
        <li>Deserunt minima vitae ipsa ipsam fugiat, harum blanditiis voluptate hic.</li>
        <li>Maiores, eum. Repellendus delectus a eius deserunt iste dolorem soluta?</li>
        <li>Alias fugiat laudantium dolores molestias quo ratione aspernatur vel ea!</li>
        <li>Cumque nulla praesentium nam, qui beatae repudiandae. Provident, ut hic.</li>
        <li>Non ex quas nesciunt ut veniam quisquam necessitatibus iste soluta!</li>
      </ul>
    </div>
  </div>
  <div class="row">
    <!-- empty row -->
    <div class="col karta m-4">
      <div class="m-4">
        <span>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Tenetur, temporibus.
          Ad exercitationem vel quis minima eaque! Perferendis temporibus doloribus dignissimos!
          Deleniti ad tempora culpa incidunt sint dicta, fugiat pariatur minus.
          Asperiores iste obcaecati unde fugiat dolorem doloremque voluptas, excepturi hic.
          Molestias saepe pariatur aperiam amet at inventore velit, quibusdam optio.
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Tenetur, temporibus.
          Ad exercitationem vel quis minima eaque! Perferendis temporibus doloribus dignissimos!
          Deleniti ad tempora culpa incidunt sint dicta, fugiat pariatur minus.
          Asperiores iste obcaecati unde fugiat dolorem doloremque voluptas, excepturi hic.
          Molestias saepe pariatur aperiam amet at inventore velit, quibusdam optio.
        </span>
      </div>
    </div>

    <div class="col karta m-4">
      <div class="m-4">
        <span>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Tenetur, temporibus.
          Ad exercitationem vel quis minima eaque! Perferendis temporibus doloribus dignissimos!
          Deleniti ad tempora culpa incidunt sint dicta, fugiat pariatur minus.
          Asperiores iste obcaecati unde fugiat dolorem doloremque voluptas, excepturi hic.
          Molestias saepe pariatur aperiam amet at inventore velit, quibusdam optio.
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Tenetur, temporibus.
          Ad exercitationem vel quis minima eaque! Perferendis temporibus doloribus dignissimos!
          Deleniti ad tempora culpa incidunt sint dicta, fugiat pariatur minus.
          Asperiores iste obcaecati unde fugiat dolorem doloremque voluptas, excepturi hic.
          Molestias saepe pariatur aperiam amet at inventore velit, quibusdam optio.
        </span>
      </div>
    </div>
  </div>

</div>
