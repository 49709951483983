<div class="container my-5">

  <h2>Seznam transakcí {{ captionSuffix }} za období {{ timeframeCaption }}</h2>

  <app-timeframe-filter (filterAdjust)="onTimeFrameSelected($event)"></app-timeframe-filter>

  <p>Filtrování:</p>
  <div class="form-check form-switch d-inline-block">
    <input class="form-check-input" type="checkbox" role="switch" id="expenseCheck" checked (click)="toggleExpense()">
    <label class="form-check-label" for="expenseCheck">Výdaje</label>
  </div>
  <div class="form-check form-switch d-inline-block ms-2">
    <input class="form-check-input" type="checkbox" role="switch" id="incomeCheck" checked (click)="toggleIncome()">
    <label class="form-check-label" for="incomeCheck">Příjmy</label>
  </div>

  <p class="mt-4">Generování:</p>
  <div class="btn btn-primary" (click)="generate()">Generovat</div>

  <table class="table table-dark table-striped table-hover mt-4">
    <thead>
      <tr>
        <th scope="col">Název</th>
        <th scope="col">Splatnost</th>
        <th scope="col" class="text-end pe-4">Má dáti</th>
        <th scope="col" class="text-end pe-4">Zbývá</th>
        <th scope="col">Kategorie</th>
        <th scope="col">Akce</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let financeTransaction of financeTransactions">
        <ng-container *ngIf="this.expense && !financeTransaction.income || this.income && financeTransaction.income">
          <td>{{financeTransaction.name}}
            <div class="badge rounded-pill bg-secondary" *ngIf="financeTransaction.amountSave > 0">anuita</div>
            <div class="badge rounded-pill bg-success"
              *ngIf="financeTransaction.amountSave < 1 && financeTransaction.income">příjem</div>
            <div class="badge rounded-pill bg-primary"
              *ngIf="financeTransaction.amountSave < 1 && !financeTransaction.income">výdaj</div>
          </td>
          <td>{{ financeTransaction.dueDate | date : 'd.M.yy'}}</td>
          <ng-container *ngIf="financeTransaction.amountSave < 1">
            <td class="text-end pe-4">{{ financeTransaction.amountShouldGive | number:'':'cs'}}</td>
            <td class="text-end pe-4">{{ financeTransaction.amountShouldGive - financeTransaction.amountGave |
              number:'':'cs'}}</td>
          </ng-container>
          <ng-container *ngIf="financeTransaction.amountSave > 0">
            <td class="text-end pe-4">{{ financeTransaction.amountSave | number:'':'cs'}}</td>
            <td class="text-end pe-4">{{ financeTransaction.amountSave - financeTransaction.amountGave |
              number:'':'cs'}}</td>
          </ng-container>


          <td>{{financeTransaction.categoryName}}</td>
          <td class="d-flex">
            <span class="ms-auto">
              <div *ngIf="financeTransaction.amountGave < 1" class="btn btn-sm btn-success me-2"
                (click)="totalPay(financeTransaction)">zaplaceno</div>
              <div class="btn btn-sm btn-success me-2" (click)="goToTransactionDetail(financeTransaction)">detail</div>
              <i class="bi bi-pen me-2" (click)="onEdit(financeTransaction.id)"></i>
              <i class="bi bi-trash text-danger" (click)="onDelete(financeTransaction.id)"></i>
            </span>
          </td>
        </ng-container>
      </tr>
    </tbody>
    <tfoot style="position:relative; top:10px">
      <tr>
        <td scope="col"><b>Suma</b></td>
        <td scope="col"></td>
        <ng-container *ngIf="!(this.expense && this.income)">
          <td scope="col" class="text-end pe-4"><b>{{ sumAmountShouldGive | number:'':'cs'}}</b></td>
          <td scope="col" class="text-end pe-4"><b>{{ sumAmountGave | number:'':'cs'}}</b></td>
        </ng-container>
        <ng-container *ngIf="this.expense && this.income">
          <td scope="col"  colspan="2" class="text-end pe-4"><b>Nemá smysl sčitat příjmy a výdaje</b></td>
        </ng-container>

        <td scope="col"></td>
        <td scope="col"></td>
      </tr>
    </tfoot>
  </table>

  <p>Přidat/editovat</p>
  <div *ngIf="!showEditForm" class="btn btn-success" (click)="newTransaction()">Nová transakce</div>
  <span #editForm>
    <ng-container *ngIf="showEditForm">
      <div class="bg-primary container p-4 rounded-3 border border-success">
        <form [formGroup]="detailForm" (ngSubmit)="onSave()">

          <div class="form-group">
            <label>Název</label>
            <input type="text" formControlName="name" class="form-control" placeholder="Název">
          </div>
          <div class="form-group">
            <label>Kategorie</label>
            <select class="form-select" (change)="changeCategory($event)" formControlName="categoryId">
              <option value="">Vyberte kategorii</option>
              <option *ngFor="let category of financeCategories" value="{{ category.id }}">
                {{ category.fullName }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label>Částka má dáti</label>
            <input type="number" formControlName="amountShouldGive" class="form-control">
          </div>
          <div class="form-group">
            <label>Částka dal</label>
            <input type="number" formControlName="amountGave" class="form-control">
          </div>
          <div class="form-group">
            <label>Částka předpověd min</label>
            <input type="number" formControlName="amountPredictionMin" class="form-control">
          </div>
          <div class="form-group">
            <label>Částka předpověd</label>
            <input type="number" formControlName="amountPrediction" class="form-control">
          </div>
          <div class="form-group">
            <label>Částka předpověd max</label>
            <input type="number" formControlName="amountPredictionMax" class="form-control">
          </div>
          <div class="form-group">
            <label>Poznámka</label>
            <input formControlName="note" class="form-control">
          </div>

          <div class="my-2">
            <div class="btn btn" (click)="this.showEditForm = false">Zavřít</div>
            <button class="btn btn-success" type="submit" [disabled]="!detailForm.valid">Uložit</button>
          </div>

        </form>
      </div>
    </ng-container>
  </span>

</div>