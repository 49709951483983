<div class="container-fluid my-5">
    <div class="d-flex flex-column">
        <div class="mx-auto">
            <h2>Přehled příjmů a výdajů za období {{ timeframeCaption }}</h2>
        </div>

        <div class="mx-auto">
            <app-timeframe-filter (filterAdjust)="onTimeFrameSelected($event)"></app-timeframe-filter>
        </div>
    </div>


    <div class="row my-4">
        <div class="col-2">

            <div class="card border-secondary bg-dark text-center">
                <div class="card-body">
                <h5 class="card-title">Příjmy</h5>
                <h6 class="card-subtitle mb-2 text-muted">{{ detail?.incomeCount}} x</h6>
                <p class="card-text fs-2">{{ detail?.incomeTotal | number:'':'cs'}} <span class="text-muted fs-6">Kč</span></p>
                </div>
            </div>
        </div>

        <div class="col-2">

            <div class="card border-secondary bg-dark text-center">
                <div class="card-body">
                    <h5 class="card-title">Výdaje</h5>
                    <h6 class="card-subtitle mb-2 text-muted">{{ detail?.expenseCount}} x</h6>
                    Celkové
                    <p class="card-text fs-2"> {{ detail?.expensesTotal | number:'':'cs'}} <span class="text-muted fs-6">Kč</span></p>
                    <h6 class="card-subtitle mb-2 text-muted">{{ detail?.expensesTotal / detail?.incomeTotal * 100 | number:'1.0-1' }} %</h6>
                    Mandatorní
                    <p class="card-text fs-2"> {{ detail?.expensesMandatory | number:'':'cs'}} <span class="text-muted fs-6">Kč</span></p>
                    <h6 class="card-subtitle mb-2 text-muted">{{ detail?.expensesMandatory / detail?.incomeTotal * 100 | number:'1.0-1' }} %</h6>
                </div>
            </div>
        </div>

        <div class="col-2">
            <div class="card border-secondary bg-dark text-center">
                <div class="card-body">
                <h5 class="card-title">Anuity</h5>
                <h6 class="card-subtitle mb-2 text-muted">{{ detail?.anuitiesCount}} x</h6>
                <h6 class="card-subtitle mb-2 text-muted">{{ detail?.anuitiesTotal / detail?.incomeTotal * 100 | number:'1.0-1' }} %</h6>
                <span class="card-text fs-2">{{ detail?.anuitiesTotal | number:'':'cs'}} <span class="text-muted fs-6">Kč</span> </span>
                </div>
            </div>
        </div>

        <div class="col-2">
            <div class="card border-secondary bg-dark text-center">
                <div class="card-body">
                <h5 class="card-title">Volné prostředky</h5>
                <h6 class="card-subtitle mb-2 text-muted">{{ 100 - detail?.expensesTotal / detail?.incomeTotal  * 100 | number:'1.0-1' }} %</h6>
                <p class="card-text fs-2">{{ detail?.incomeTotal - detail?.expensesTotal | number:'':'cs'}} <span class="text-muted fs-6">Kč</span></p>
                </div>
            </div>
        </div>

        <div class="col-2">
            <div class="card border-secondary bg-dark text-center">
                <div class="card-body">
                <h5 class="card-title">Rezervy</h5>
                <h6 class="card-subtitle mb-2 text-muted">3 x výdaje</h6>
                <p class="card-text fs-2">{{ detail?.expensesTotal * 3 | number:'':'cs'}}     <span class="text-muted fs-6">Kč</span></p>
                <h6 class="card-subtitle mb-2 text-muted">Suma anuit do konce obodobí</h6>
                <p class="card-text fs-2">{{ detail?.anuitiesSumTillEndOfTimeFrame | number:'':'cs' }}<span class="text-muted fs-6">Kč</span></p>
                <h6 class="card-subtitle mb-2 text-muted">Celkem</h6>
                <p class="card-text fs-2">{{ detail?.expensesTotal * 3 + detail?.anuitiesSumTillEndOfTimeFrame | number:'':'cs' }}<span class="text-muted fs-6">Kč</span></p>
                </div>
            </div>
        </div>

        <div class="col-2">
            <div class="card border-secondary bg-dark text-center">
                <div class="card-body">
                <h5 class="card-title">Net worth</h5>
                <h6 class="card-subtitle mb-2 text-muted">Akcie</h6>
                <p class="card-text fs-2">{{ detail.stocks| number:'':'cs' }} <span class="text-muted fs-6">Kč</span></p>
                <h6 class="card-subtitle mb-2 text-muted">Nemovitosti</h6>
                <p class="card-text fs-2">TBD <span class="text-muted fs-6">Kč</span></p>
                <h6 class="card-subtitle mb-2 text-muted">Hotovost</h6>
                <p class="card-text fs-2">{{ detail.cash | number:'':'cs' }}<span class="text-muted fs-6">Kč</span></p>
                <h6 class="card-subtitle mb-2 text-muted">Dluhy</h6>
                <p class="card-text fs-2">{{ detail.debts | number:'':'cs' }} <span class="text-muted fs-6">Kč</span></p>
                <h6 class="card-subtitle mb-2 text-muted">Pohledavky</h6>
                <p class="card-text fs-2">{{ detail.claims| number:'':'cs' }} <span class="text-muted fs-6">Kč</span></p>
                <h6 class="card-subtitle mb-2 text-muted">Celkem</h6>
                <p class="card-text fs-2">{{ detail.stocks +  detail.cash + detail.claims - detail.debts | number:'':'cs' }}<span class="text-muted fs-6">Kč</span></p>
                </div>
            </div>
        </div>

    </div>


</div>
