<div class="container-fluid mt-3 ">
  <div class="row">

      <div *ngFor="let container of tasks" class="col-lg-2">
        <div class="card bg-primary ">
          <div class="card-header">
            <b>{{container.name}}</b>
          </div>
          <ul *ngFor="let task of container.tasks" class="list-group list-group-flush">
            <li class="list-group-item bg-primary d-flex">
              <input type="checkbox" [checked]="task.completed" class="me-3 align-self-center">
              <span>{{task.name}}</span>
              <span class="ms-auto"><i class="bi bi-trash text-danger"></i></span>
            </li>
          </ul>
          <div *ngIf="detail">
            <input type="text" class="form-control" [(ngModel)]="detail.name">
          </div>
          <div *ngIf="detail == null" class="btn btn-success" (click)="newTask()">Nový </div>
          <div *ngIf="detail !== null" class="btn btn-success" (click)="newTask()">Uložit </div>
          <div *ngIf="detail !== null" class="btn btn-danger" (click)="this.detail = null">Zpět</div>
          <div class="card-footer text-center ">
            {{container.completed }}/{{container.total}} splněno
          </div>
        </div>
      </div>


  </div>

</div>

