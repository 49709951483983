<div class="container my-5">
  <h2>Seznam dluhů a pohledávek</h2>

  <ng-container>
    <table class="table table-dark table-striped table-hover mt-4">
      <thead>
        <tr>
          <th scope="col">Název</th>
          <th scope="col">Typ</th>
          <th scope="col">Částka</th>
          <th scope="col">Datum vytvoření</th>
          <th scope="col">Akce</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let debtClaim of debtClaims">
          <td>{{ debtClaim.transactionName }}</td>
          <td *ngIf="debtClaim.type == 'claim'">Pohledávka</td>
          <td *ngIf="debtClaim.type == 'debt'">Dluh</td>
          <td>{{ debtClaim.amount | currency:'CZK':'symbol':'1.0-2' }}</td>
          <td>{{ debtClaim.creationDate | date:'dd.MM.yyyy' }}</td>
          <td class="d-flex">
            <span class="ms-auto">
              <i class="bi bi-pen me-2" (click)="onEdit(debtClaim.id)"></i>
              <i class="bi bi-trash text-danger" (click)="onDelete(debtClaim.id)"></i>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </ng-container>

  <p>Přidat/editovat</p>

  <div *ngIf="!showEditForm" class="btn btn-success" (click)="newDebtClaim()">Nový záznam</div>
  <ng-container *ngIf="showEditForm">
    <form [formGroup]="detailForm" (ngSubmit)="onSave()">
      <div class="form-group">
        <label>Název</label>
        <input type="text" formControlName="transactionName" class="form-control" placeholder="Název">
      </div>
      <div class="form-group">
        <label>Typ</label>
        <select class="form-select" formControlName="type">
          <option value="" disabled>Vyberte typ</option>
          <option value="debt">Dluh</option>
          <option value="claim">Pohledávka</option>
        </select>
      </div>
      <div class="form-group">
        <label>Částka</label>
        <input type="number" formControlName="amount" class="form-control">
      </div>
      <div class="form-group">
        <label>Poznámka</label>
        <input type="text" formControlName="note" class="form-control">
      </div>
      <div class="form-group">
        <label>Historie plateb</label>
        <textarea class="form-control" [value]="paymentHistory" readonly></textarea>
      </div>

      <div class="mt-3">
        <button class="btn btn-primary" (click)="this.showEditForm = false">Zavřít</button>
        <button class="btn btn-success ms-2" type="submit" [disabled]="!detailForm.valid">Uložit</button>
      </div>
    </form>
  </ng-container>
</div>
