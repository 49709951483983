import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import { Observable } from 'rxjs';
import { Task } from '../model/task.model';

@Injectable({providedIn: 'root'})
export class TaskService {

  constructor(private http: HttpClient, private config: ConfigService) { }

  getAll(): Observable<Task[]> {
    return this.http.get<Task[]>(this.config.baseUrl + `api/task/all`);
  }

  complete(taskId: number): Observable<any> {
    return this.http.get(this.config.baseUrl + `api/task/${taskId}/complete`);
  }

  uncomplete(taskId: number): Observable<any> {
    return this.http.get(this.config.baseUrl + `api/task/${taskId}/uncomplete`);
  }

  getById(taskId: number): Observable<Task> {
    return this.http.get<Task>(this.config.baseUrl + `api/task/${taskId}`);
  }

  create(task: Task): Observable<any> {
   return this.http.post(this.config.baseUrl + 'api/task/', task);
  }

  delete(taskId: number): Observable<any> {
    return this.http.delete(this.config.baseUrl + `api/task/${taskId}`);
  }

}
