<div class="mt-3 ms-3">

  <h1>Clanek xyz</h1>


  <editor
  [(ngModel)]="articleContent"
  [inline]="inline"
  [init]="{
    menubar: false
  }"
  ></editor>

  <button class="btn btn-primary m-1" (click)="save()">Zasejvovat</button>
  <button class="btn btn-secondary m-1" (click)="switchInline()">Togle inline</button>

</div>
