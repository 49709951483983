<nav class="navbar p-3 shadow-large navbar-expand-lg sticky-top" style="background-color: #222b45; margin-top:100px;">
  <div class="container-fluid">
    <a class="navbar-brand fw-bold text-white">PandaBunda</a>
    <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">

        <li *ngFor="let item of navItems" class="nav-item">
          <a class="nav-link active text-white" routerLink="{{ item.routerLink }}">{{ item.content }}</a>
        </li>

      </ul>
      <form class="d-flex">
        <input class="form-control me-2" type="search" placeholder="Hlodat" >
      <button class="btn btn-outline-success" type="submit">Hlodej</button>
      </form>
    </div>
  </div>
</nav>
