import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ConfigService } from './config.service';
import { Observable } from 'rxjs';
import { FinanceTransaction } from '../model/finance-transaction.model';
import { TimeFrameFilter } from '../model/timeframe-filter.model';


@Injectable({providedIn: 'root'})
export class FinanceTransactionService {


  constructor(private http: HttpClient, private config: ConfigService) { }


  getAll(): Observable<FinanceTransaction[]> {
    return this.http.get<FinanceTransaction[]>(this.config.baseUrl + 'api/finance/transaction/all');
  }


  getByTimeframe(timeframe: TimeFrameFilter): Observable<FinanceTransaction[]> {
    return this.http.post<FinanceTransaction[]>(this.config.baseUrl + 'api/finance/transaction/byTimeFrame', timeframe);
}

  getByCategory(id: number): Observable<FinanceTransaction[]> {
    return this.http.get<FinanceTransaction[]>(this.config.baseUrl + `api/finance/transaction/byCategory/${id}`);
  }

  getByCategoryAndTimeFrame(id: number, timeframe: TimeFrameFilter): Observable<FinanceTransaction[]> {
    return this.http.post<FinanceTransaction[]>(this.config.baseUrl + `api/finance/transaction/byCategory/${id}/byTimeFrame`, timeframe);
  }

  getByPrototype(id: number): Observable<FinanceTransaction[]> {
    return this.http.get<FinanceTransaction[]>(this.config.baseUrl + `api/finance/transaction/byPrototype/${id}`);
  }

  generateFromPrototype(id: number, count: number): Observable<any> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("count", count);
    return this.http.post(this.config.baseUrl + `api/finance/transaction/generateFromPrototype/${id}`, null, {params: queryParams});
  }

  generate(filter: TimeFrameFilter) {
    return this.http.put<Number>(this.config.baseUrl + `api/finance/transaction/generate`, filter);
  }


  getById(id: number): Observable<FinanceTransaction> {
    return this.http.get<FinanceTransaction>(this.config.baseUrl + `api/finance/transaction/${id}`);
  }

  create(dto: FinanceTransaction): Observable<any> {
    return this.http.post(this.config.baseUrl + 'api/finance/transaction/', dto);
  }

  save(dto: FinanceTransaction): Observable<any> {
    return this.http.put(this.config.baseUrl + `api/finance/transaction/${dto.id}`, dto);
   }

  delete(id: number): Observable<any> {
    return this.http.delete(this.config.baseUrl + `api/finance/transaction/${id}`);
  }


  partialPay(id: number, amount: number) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("amount", amount);

    return this.http.post<FinanceTransaction[]>(this.config.baseUrl + `api/finance/transaction/${id}/partialPay`, null, {params: queryParams});
  }
  totalPay(id: number) {
    return this.http.put(this.config.baseUrl + `api/finance/transaction/${id}/totalPay`, null);
  }

}
