<div class="container my-5">

<p>Seznam opakování</p>
  
<ul *ngFor="let financeRecurringType of financeRecurringTypes" class="list-group list-group-flush">
    <li class="list-group-item bg-primary d-flex">
      <span>{{financeRecurringType.name}}</span>
      <span class="ms-auto"><i class="bi bi-pen text-pirmary me-2" (click)="onEdit(financeRecurringType.id)"></i> <i class="bi bi-trash text-danger" (click)="onDelete(financeRecurringType.id)"></i></span>
    </li>
  </ul>


<p>Přidat/editovat</p>
<div *ngIf="!showEditForm" class="btn btn-success" (click)="newFinanceRecurringType()">Nový </div>
<ng-container *ngIf="showEditForm">
  <div class="bg-primary container p-4 rounded-3 border border-success">
    <form [formGroup]="detailForm" (ngSubmit)="onSave()">

      <div class="form-group my-3">
        <label>Název</label>
        <input type="text"  formControlName="name" class="form-control" placeholder="Název">
      </div>
      <div class="form-group my-3">
        <label>Den splátnosti</label>
        <input type="number"  formControlName="dayDue" class="form-control" placeholder="Den splatnosti">
        <small class="form-text text-muted">Kolikáty den v měsíci je splatnost</small>
      </div>
      <div class="form-group my-3">
        <label>Měsíc splatnosti</label>
        <div>
          <div>
            <div class="btn btn-sm btn-outline-warning"  id="inlineCheckboxAll" (click)="selectAllMonths()">Označ všechny</div>  
            <div class="btn btn-sm btn-outline-warning ms-2"  id="inlineCheckboxAll" (click)="deSelectAllMonths()">Označ žádný</div>  
          </div>
          <div class="form-check form-check-inline ps-0" *ngFor="let month of months, let idx = index">
            <input  type="checkbox" id="inlineCheckbox{{ idx }}" (change)="onMonthToggle($event, idx)" [checked]="monthsCheckMap[idx]">
            <label class="form-check-label" for="inlineCheckbox{{ idx }}">{{ month }}</label>
          </div>
        </div>
      </div>


      <div class="btn btn" (click)="this.showEditForm = false">Zavřít</div>
      <button class="btn btn-success" type="submit" [disabled]="!detailForm.valid">Uložit</button>

    </form>
  </div>
</ng-container>


</div>

