<div class="row">
  <div class="col-3 d-flex justify-content-center align-items-center">
    Období
  </div>
  <div class="col">
    <div class="btn-group btn-group-toggle my-3" data-toggle="buttons">
      <label class="btn btn-secondary" [class.active]="isSelectMonth">
        <input class="btn-check" type="radio" name="options" autocomplete="off" (click)="toggleTimeframeSelection('month')" [disabled]="isSelectMonth">Měsíc
      </label>
      <label class="btn btn-secondary" [class.active]="isSelectQuarter">
        <input class="btn-check" type="radio" name="options" autocomplete="off" (click)="toggleTimeframeSelection('quarter')" [disabled]="isSelectQuarter">Kvartál
      </label>
      <label class="btn btn-secondary" [class.active]="isSelectYear">
        <input class="btn-check" type="radio" name="options" autocomplete="off" (click)="toggleTimeframeSelection('year')" [disabled]="isSelectYear">Rok
      </label>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-3 d-flex justify-content-center align-items-center">
    Rok
  </div>
  <div class="col">
    <div class="btn-group btn-group-toggle mb-1" data-toggle="buttons">
      <label class="btn btn-secondary" [class.active]="selectedYear == year" *ngFor="let year of years">
        <input class="btn-check" type="radio" name="options" autocomplete="off" [checked]="selectedYear == year" (click)="onYearSelected(year)">{{ year }}
      </label>
    </div>
  </div>
</div>
<div class="row mt-3" *ngIf="isSelectMonth">
  <div class="col-3 d-flex justify-content-center align-items-center">
    Měsíc
  </div>
  <div class="col col-9">
    <div class="btn-group btn-group-toggle mb-1" data-toggle="buttons">
      <label class="btn btn-secondary" [class.active]="selectedMonth == i" *ngFor="let month of months, let i = index">
        <input class="btn-check" type="radio" name="options" autocomplete="off" [checked]="selectedMonth == i" (click)="onMonthSelected(i)">{{ month }}
      </label>
    </div>
  </div>
</div>
<div class="row mt-3" *ngIf="isSelectQuarter || (!isSelectMonth && !isSelectQuarter)">
  <div class="col-3 d-flex justify-content-center align-items-center" [class.invisible]="!isSelectMonth && !isSelectQuarter">
    Kvartál
  </div>
  <div class="col" [class.invisible]="!isSelectMonth && !isSelectQuarter">
    <div class="btn-group btn-group-toggle mb-1" data-toggle="buttons">
      <label class="btn btn-secondary" [class.active]="selectedQuarter == i" *ngFor="let quarter of quarters, let i = index">
        <input class="btn-check" type="radio" name="options" autocomplete="off" [checked]="selectedQuarter == i" (click)="onQuarterSelected(i)">{{ quarter }}
      </label>
    </div>
  </div>
</div>
