import { Component, OnInit } from '@angular/core';
import { Article } from 'src/app/model/article.model';
import { TopNavService } from 'src/app/services-local/top-nav-service';
import { ArticleService } from 'src/app/services/article.service';

@Component({
  selector: 'app-article-main',
  templateUrl: './article-main.component.html',
  styleUrls: ['./article-main.component.scss']
})
export class ArticleMainComponent implements OnInit {

  articles: Article[] = [];

  constructor(private topNavService: TopNavService, private articleService: ArticleService) { }

  ngOnInit(): void {
    this.topNavService.setNewContent([{content: 'Nový článek', routerLink: 'articles/new'}]);
    this.loadData();
  }


  loadData(): void {

    this.articleService.getAll().subscribe( res => {
      this.articles = res;
    });
  }

}
