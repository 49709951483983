import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import { Observable } from 'rxjs';
import { FinanceCategory } from '../model/finance-category.model';

@Injectable({providedIn: 'root'})
export class FinanceCategoryService {

  constructor(private http: HttpClient, private config: ConfigService) { }


  getAll(): Observable<FinanceCategory[]> {
    return this.http.get<FinanceCategory[]>(this.config.baseUrl + 'api/finance/category/all');
  }

  getAllStructured(): Observable<FinanceCategory[]> {
    return this.http.get<FinanceCategory[]>(this.config.baseUrl + 'api/finance/category/all/structured');
  }

  getById(categoryId: number): Observable<FinanceCategory> {
    return this.http.get<FinanceCategory>(this.config.baseUrl + `api/finance/category/${categoryId}`);
  }

  create(category: FinanceCategory): Observable<any> {
   return this.http.post(this.config.baseUrl + 'api/finance/category/', category);
  }

  save(category: FinanceCategory): Observable<any> {
    return this.http.put(this.config.baseUrl + `api/finance/category/${category.id}`, category);
   }

  delete(categoryId: number): Observable<any> {
    return this.http.delete(this.config.baseUrl + `api/finance/category/${categoryId}`);
  }

}
