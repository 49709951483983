import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FinanceOverview } from 'src/app/model/finance-overview.model';
import { TimeFrameFilter } from 'src/app/model/timeframe-filter.model';
import { TopNavService } from 'src/app/services-local/top-nav-service';
import { FinanceOverviewService } from 'src/app/services/finance-overview.service';
import { TimeframeAdjustEvent } from 'src/app/shared/timeframe-filter/timeframe-adjusted-event';
import { FinanceMenuItems } from '../FinanceMenuItems';

@Component({
  selector: 'app-finance-overview',
  templateUrl: './finance-overview.component.html',
  styleUrls: ['./finance-overview.component.scss']
})
export class FinanceOverviewComponent {


  constructor(private topNavService: TopNavService,  private financeOverviewService: FinanceOverviewService, private router: Router){}


  detail: FinanceOverview;
  filter: TimeFrameFilter;
  timeframeCaption: string = "";

  ngOnInit(): void {
    this.topNavService.setNewContent(FinanceMenuItems.NAVIGATION_ITEMS);
    this.filter = {year : new Date().getFullYear()}
  }



  loadData(): void {
    this.financeOverviewService.getFinanceOverview(this.filter).subscribe( data => this.detail = data);
  }


  onTimeFrameSelected(evt: TimeframeAdjustEvent) {

    this.filter.year = evt.selectedYear;
    this.filter.month = evt.selectedMonth;
    this.filter.quartal = evt.selectedQuarter;
    this.timeframeCaption = evt.prettyCaption;

    this.loadData();
  }




}
