import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication.service';
import { Observable } from 'rxjs';
import { User } from '../model/user.model';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  CREDENTIALS_OK_NEED_TOTP = 'CREDENTIALS_OK_NEED_TOTP';
  BAD_CREDENTIALS = 'BAD_CREDENTIALS';
  TOTP_SECRET_MISSING = 'TOTP_SECRET_MISSING';
  BAD_TOTP = 'BAD_TOTP';
  AUTH_COMPLETE = 'AUTH_COMPLETE';

  loginForm: UntypedFormGroup;
  loading = false;
  submitted = false;
  showTotp = false;
  returnUrl: string;
  totpImageUrl: string;


  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
) {
    // redirect to home if already logged in
    if (this.authenticationService.currentUserValue) {
        this.router.navigate(['/']);
    }
}

  ngOnInit() {
      this.loginForm = this.formBuilder.group({
          username: ['', Validators.required],
          password: ['', Validators.required],
          totp: ['']
      });

      // get return url from route parameters or default to '/'
      this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }


  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.loginForm.invalid) {
        return;
    }

    this.loading = true;
    let authResult: Observable<User>;
    authResult = this.authenticationService.loginViaUsernameAndPassword(this.f.username.value, this.f.password.value);


    authResult.pipe(first())
    .subscribe(
        data =>  this.processAuthResult(data),
        error => this.processError(error)
    );
  }

  processAuthResult(data: User): void {
      this.authenticationService.persistToken(data.token);
      this.router.navigate([this.returnUrl]);
  }


  processError(error: string): void {
      alert(error); // TODO zhezcit
      console.log(error);
      this.loading = false;
  }

}
