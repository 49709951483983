import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { FinanceCategory } from 'src/app/model/finance-category.model';
import { TopNavService } from 'src/app/services-local/top-nav-service';
import { FinanceCategoryService } from 'src/app/services/finance-category.service';
import { FinanceMenuItems } from '../FinanceMenuItems';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-finance-main',
  templateUrl: './finance-main.component.html',
  styleUrls: ['./finance-main.component.scss'],
  animations: [
    trigger('slideIn', [
      state('hidden', style({
        height: '0px',
        opacity: 0,
        overflow: 'hidden'
      })),
      state('visible', style({
        height: '*',
        opacity: 1,
      })),
      transition('hidden => visible', [
        animate('300ms ease-out')
      ]),
      transition('visible => hidden', [
        animate('300ms ease-in')
      ])
    ])
  ]
})
export class FinanceMainComponent implements OnInit {

  financeCategories: FinanceCategory[] = [];

  constructor(private topNavService: TopNavService, private financeCategoryService: FinanceCategoryService,
    private router: Router){}

  ngOnInit(): void {
    this.topNavService.setNewContent(FinanceMenuItems.NAVIGATION_ITEMS);
    this.loadData();
  }

   // Mapa viditelnosti tlačítek pro jednotlivé kategorie (používá id kategorie)
   visibleButtonsMap: { [id: number]: boolean } = {};

   // Přepínání viditelnosti tlačítek
   toggleButtonsVisible(id: number) {
     this.visibleButtonsMap[id] = !this.visibleButtonsMap[id];
   }

   // Kontrola, zda jsou tlačítka viditelná pro danou kategorii
   isButtonsVisible(id: number): boolean {
     return this.visibleButtonsMap[id] || false; // Pokud není v mapě, vrací false
   }

  addCategory(name: string, left: number, parentId: number) {
    console.log("Add category " + name + " " + left + " " + parentId);

    var category = {name, insertAfterPos : left, parentId};

    this.financeCategoryService.create(category).subscribe( res =>
      {
        this.loadData();
      });

  }

  addTemplateToCategory(category: FinanceCategory)  {
    const state = { category, mode : 'add'};
    this.router.navigate(['finance/template'], {state});
  }

  addTransactionToCategory(category: FinanceCategory) {
    const state = { category, mode : 'add'};
    this.router.navigate(['finance/transaction'], {state});

  }

  showTemplates(category: FinanceCategory)  {
    const state = { category, mode : 'show'};
    this.router.navigate(['finance/template'], {state});
  }

  deleteCategory(id: number) {

    this.financeCategoryService.delete(id).subscribe( res =>
      {
        this.loadData();
      });

  }


  loadData(): void {

     this.financeCategoryService.getAllStructured().subscribe( res => {
       this.financeCategories = res;
     });
  }

}
