import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app/app.component';
import { ArticleDetailComponent } from './app/article/article-detail/article-detail.component';
import { ArticleMainComponent } from './app/article/article-main/article-main.component';
import { DashboardComponent } from './app/dashboard/dashboard.component';
import { LoginComponent } from './app/login/login.component';
import { TaskMainComponent } from './app/task/task-main/task-main.component';
import { DummyContentComponent } from './app/temp/dummy-content/dummy-content.component';
import { AuthGuard } from './app/_helper/auth.guard';
import { FinanceMainComponent } from './app/finance/finance-main/finance-main.component';
import { FinanceRecurringTypeComponent } from './app/finance/finance-recurring-type/finance-recurring-type.component';
import { FinanceTransactionTemplateComponent } from './app/finance/finance-transaction-template/finance-transaction-template.component';
import { FinanceTransactionComponent } from './app/finance/finance-transaction/finance-transaction.component';
import { FinanceTransactionDetailComponent } from './app/finance/finance-transaction-detail/finance-transaction-detail.component';
import { FinanceTransactionTemplateDetailComponent } from './app/finance/finance-transaction-template-detail/finance-transaction-template-detail.component';
import { FinanceOverviewComponent } from './app/finance/finance-overview/finance-overview.component';
import { FinanceLoansDebtsComponent } from './app/finance/finance-loans-debts/finance-loans-debts.component';


const appRoutes: Routes = [
  { path: '', component: DashboardComponent, canActivate: [AuthGuard], children : [
      { path: '', redirectTo: 'overview', pathMatch: 'full' },
      { path: 'overview', component: DummyContentComponent },
      { path: 'tasks', component: TaskMainComponent },

      //articles
      { path: 'articles', component: ArticleMainComponent },
      { path: 'articles/new', component: ArticleDetailComponent },
      { path: 'articles/:id', component: ArticleDetailComponent },

      //finances
      { path: 'finance',  children: [
        { path: '', redirectTo: 'main', pathMatch: 'full'},
        { path: 'main', component: FinanceMainComponent},
        { path: 'category', component: FinanceMainComponent},
        { path: 'transaction', component: FinanceTransactionComponent },
        { path: 'transaction-detail/:id', component: FinanceTransactionDetailComponent },
        { path: 'template', component: FinanceTransactionTemplateComponent },
        { path: 'template-detail/:id', component: FinanceTransactionTemplateDetailComponent },
        { path: 'recurringType', component: FinanceRecurringTypeComponent },
        { path: 'overview', component: FinanceOverviewComponent },
        { path: 'debtsandloans', component: FinanceLoansDebtsComponent },

        ]
      },
  ]
  },
  { path: 'login', component: LoginComponent },
  // otherwise redirect to home
 // { path: '**', redirectTo: '' }

];

export const routing = RouterModule.forRoot(appRoutes);
