
<div class="container-fluid">
  <app-top-navbar></app-top-navbar>

  <div class="container-fluid d-flex align-items-stretch">
        <div>
          <app-sidebar></app-sidebar>
        </div>
        <div class="flex-fill">
          <router-outlet></router-outlet>
        </div>


  </div>
</div>


