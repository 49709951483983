import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Article } from 'src/app/model/article.model';
import { TopNavService } from 'src/app/services-local/top-nav-service';
import { ArticleService } from 'src/app/services/article.service';

@Component({
  selector: 'app-article-detail',
  templateUrl: './article-detail.component.html',
  styleUrls: ['./article-detail.component.scss']
})
export class ArticleDetailComponent implements OnInit {

  article: Article;
  articleContent = '';
  inline = true;

  constructor(private route: ActivatedRoute, private topNavService: TopNavService, private articleService: ArticleService) { }


  ngOnInit(): void {
    this.topNavService.setNewContent([
      {content: 'Editovat', routerLink: 'articles/edit'},
      {content: 'Smazat', routerLink: 'articles/delete'},
    ]);
    this.loadData();
  }




  loadData(): void  {
    const id = this.route.snapshot.paramMap.get('id');

    if (id != null) {
      console.log(id);
      this.articleService.getById(+id).subscribe( article => {
        this.article = article;
        this.articleContent = article.text;
      });
    } else {
      this.article = {text: '<h2>Obsah</h2>'};
      this.articleContent = this.article.text;
    }
  }

  save(): void {
    if (this.article.id != null) {
      this.article.text = this.articleContent;
      this.articleService.save(this.article).subscribe( ok => {
        console.log('saved');
      });

    } else {
      this.article.text = this.articleContent;
      this.article.name = this.article.text.substring(0, 30); //hack dokud to nepujde editovat
      this.articleService.create(this.article).subscribe(res => {
        console.log('saved - new');
      });

    }

  }

  switchInline() {

  };

}
