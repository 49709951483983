import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import { Observable } from 'rxjs';
import { Article } from '../model/article.model';

@Injectable({providedIn: 'root'})
export class ArticleService {

  constructor(private http: HttpClient, private config: ConfigService) { }


  getAll(): Observable<Article[]> {
    return this.http.get<Article[]>(this.config.baseUrl + 'api/article/all');
  }

  getById(articleId: number): Observable<Article> {
    return this.http.get<Article>(this.config.baseUrl + `api/article/${articleId}`);
  }

  create(article: Article): Observable<any> {
   return this.http.post(this.config.baseUrl + 'api/article/', article);
  }

  save(article: Article): Observable<any> {
    return this.http.put(this.config.baseUrl + `api/article/${article.id}`, article);
   }

  delete(articleId: number): Observable<any> {
    return this.http.delete(this.config.baseUrl + `api//article/${articleId}`);
  }

}
