import { Component, OnInit } from '@angular/core';
import { FinanceMenuItems } from '../FinanceMenuItems';
import { FinanceDebtClaimService } from 'src/app/services/finance-debtclaim.service';
import { TopNavService } from 'src/app/services-local/top-nav-service';
import { FinanceDebtClaim } from 'src/app/model/finance-debtclaim.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-finance-loans-debts',
  templateUrl: './finance-loans-debts.component.html',
  styleUrls: ['./finance-loans-debts.component.scss'],
})
export class FinanceLoansDebtsComponent implements OnInit {



  debtClaims : Array<FinanceDebtClaim> = [];
  showEditForm: boolean = false;
  detailForm: FormGroup;
  currentDebtClaimId: number | null = null;
  paymentHistory: string = '';  // readonly Zobrazení paymentHistory

  constructor(
    private topNavService: TopNavService,
    private debtClaimService: FinanceDebtClaimService,
    private fb: FormBuilder
  ) {
    this.detailForm = this.fb.group({
      transactionName: ['', Validators.required],
      type: ['', Validators.required],
      amount: [0, [Validators.required, Validators.min(0)]],
      note: ['']
    });
  }



  ngOnInit(): void {
    this.topNavService.setNewContent(FinanceMenuItems.NAVIGATION_ITEMS);
    this.loadData();
  }

  // Načtení seznamu pohledávek a dluhů
  loadData(): void {
    this.debtClaimService.getAll().subscribe(data => {
      this.debtClaims = data;
    });
  }

  // Otevře formulář pro přidání nové pohledávky nebo dluhu
  newDebtClaim(): void {
    this.showEditForm = true;
    this.detailForm.reset();  // Vyprázdní formulář
    this.currentDebtClaimId = null;  // Nastaví, že se přidává nový záznam
    this.paymentHistory = '';  // Resetuje historii plateb
  }

  // Načtení konkrétní pohledávky pro úpravu
  onEdit(id: number): void {
    this.debtClaimService.get(id).subscribe(debtClaim => {
      this.currentDebtClaimId = id;
      this.detailForm.patchValue({
        transactionName: debtClaim.transactionName,
        type: debtClaim.type,
        amount: debtClaim.amount,
        note: debtClaim.note
      });
      this.showEditForm = true;  // Zobrazí formulář
      this.paymentHistory = debtClaim.paymentHistory || '';  // Načtení historie plateb
    });
  }

  // Uložení nového nebo upraveného záznamu
  onSave(): void {
    const debtClaim: FinanceDebtClaim = this.detailForm.value;

    if (this.currentDebtClaimId) {
      // Pokud upravujeme existující záznam
      this.debtClaimService.update(this.currentDebtClaimId, debtClaim).subscribe(() => {
        this.loadData();  // Znovu načte seznam
        this.showEditForm = false;
      });
    } else {
      // Pokud vytváříme nový záznam
      this.debtClaimService.insert(debtClaim).subscribe(() => {
        this.loadData();  // Znovu načte seznam
        this.showEditForm = false;
      });
    }
  }

  onDelete(id: number): void {
    if (confirm('Opravdu chcete smazat tento záznam?')) {
      this.debtClaimService.delete(id).subscribe(() => {
        this.loadData();
      });
    }
  }

}
