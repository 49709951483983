import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TimeframeAdjustEvent } from './timeframe-adjusted-event';

@Component({
  selector: 'app-timeframe-filter',
  templateUrl: './timeframe-filter.component.html',
  styleUrls: ['./timeframe-filter.component.scss']
})
export class TimeframeFilterComponent implements OnInit {

  @Input()
  yearSelectEnabled: boolean = true;
  @Input()
  monthSelectEnabled: boolean = true;
  @Input()
  quarterSelectEnabled: boolean = true;

  @Input()
  initialDate: Date = new Date();

  @Input()
  emitEventOnInit: boolean = true


  @Output()
  filterAdjust = new EventEmitter<TimeframeAdjustEvent>();


  months: string[] = ['leden', 'únor', 'březen', 'duben', 'květen', 'červen', 'červenec', 'srpen', 'září', 'říjen', 'listopad', 'prosinec'];
  years: number[] = [2023, 2024, 2025, 2026];
  quarters: string[] = ['1. kvartál', '2. kvartál', '3. kvartál', '4. kvartál'];


  selectedMonth: number;
  selectedQuarter: number;
  selectedYear: number;
  isSelectMonth = true;
  isSelectQuarter = false;
  isSelectYear = false;

  ngOnInit(): void {
    //todo nezehlednuje scale
   this.selectedMonth = this.initialDate.getMonth();
   this.selectedYear = this.initialDate.getUTCFullYear();

   if (this.emitEventOnInit) {
      this.emitEvent();
   }

  }




  toggleTimeframeSelection(scale: 'month' | 'quarter' | 'year') {
    switch (scale) {
      case 'month': {
        if (this.selectedYear === null) {
          this.selectedYear = new Date().getFullYear();
        }
        this.isSelectMonth = true;
        this.isSelectQuarter = false;
        this.isSelectYear = false;
        break;
      }
      case 'quarter': {
        if (this.selectedYear === null) {
          this.selectedYear = new Date().getFullYear();
        }
        this.isSelectMonth = false;
        this.isSelectQuarter = true;
        this.isSelectYear = false;
        break;
      }
      case 'year': {
        this.selectedYear = null;
        this.isSelectMonth = false;
        this.isSelectQuarter = false;
        this.isSelectYear = true;
      }
    }
  }

  onMonthSelected(month: number) {
    if (this.selectedMonth === month) {
      return;
    }
    this.selectedMonth = month;
    this.selectedQuarter = null;
    this.emitEvent();
  }

  onQuarterSelected(quarter: number) {
    if (this.selectedQuarter === quarter) {
      return;
    }
    this.selectedQuarter = quarter;
    this.selectedMonth = null;
    this.emitEvent();
  }

  onYearSelected(year: number) {
    if (this.selectedYear === year) {
      return;
    }
    this.selectedYear = year;
    if (this.isSelectMonth) {
      if (this.selectedMonth == null) {
        return;
      }
      this.emitEvent();
    } else if (this.isSelectQuarter) {
      if (this.selectedQuarter == null) {
        return;
      }
      this.emitEvent();
    } else {
      this.selectedQuarter = null;
      this.selectedMonth = null;
      this.emitEvent();
    }
  }

  private emitEvent() {
    let niceMonth = this.selectedMonth === null ? null : this.selectedMonth + 1;
    let niceQuarter = this.selectedQuarter === null ? null : this.selectedQuarter + 1;

    let prettyCaption = (this.selectedMonth == null ? '' : this.months[this.selectedMonth]) +  " " 
        + (this.selectedQuarter == null ? '' : this.quarters[this.selectedQuarter]) + ' '+  this.selectedYear;
    let event = {selectedYear: this.selectedYear, selectedMonth: niceMonth, selectedQuarter: niceQuarter, prettyCaption: prettyCaption}
    this.filterAdjust.emit(event);
  }

}
