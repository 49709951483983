import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import { Observable } from 'rxjs';
import { FinanceRecurringType } from '../model/finance-recurring-type.model';

@Injectable({providedIn: 'root'})
export class FinanceRecurringTypeService {

  constructor(private http: HttpClient, private config: ConfigService) { }


  getAll(): Observable<FinanceRecurringType[]> {
    return this.http.get<FinanceRecurringType[]>(this.config.baseUrl + 'api/finance/transactionRecurringType/all');
  }

  getById(recurringTypeId: number): Observable<FinanceRecurringType> {
    return this.http.get<FinanceRecurringType>(this.config.baseUrl + `api/finance/transactionRecurringType/${recurringTypeId}`);
  }

  create(recurringType: FinanceRecurringType): Observable<any> {
   return this.http.post(this.config.baseUrl + 'api/finance/transactionRecurringType/', recurringType);
  }

  save(recurringType: FinanceRecurringType): Observable<any> {
    return this.http.put(this.config.baseUrl + `api/finance/transactionRecurringType/${recurringType.id}`, recurringType);
   }

  delete(recurringTypeId: number): Observable<any> {
    return this.http.delete(this.config.baseUrl + `api/finance/transactionRecurringType/${recurringTypeId}`);
  }

}
