<div class="container my-5">

  <ul class="list-group border-end">
    <ng-template #treeView let-data let-root="root">
      <!-- Přidání kořenové kategorie -->
      <li *ngIf="root" class="list-group-item">
        <div class="d-flex align-items-center">
          <input #categoryName class="form-control form-control-sm me-2" placeholder="Název kategorie">
          <button class="btn btn-primary btn-sm" (click)="addCategory(categoryName.value, 0, null)">
            <i class="bi bi-plus-circle"></i> Přidat kořenovou kategorii
          </button>
        </div>
      </li>

      <!-- Výpis kategorií -->
      <li *ngFor="let category of data" class="list-group-item pe-0 pb-0 border-end-0">
        <!-- Pokud se přidává podkategorie -->
        <div *ngIf="category.addChildren">
          <div class="d-flex align-items-center">
            <span>{{ category.name }}</span>
            <input #categoryName class="form-control form-control-sm ms-2" placeholder="Název podkategorie">
            <button class="btn btn-primary btn-sm ms-2" (click)="addCategory(categoryName.value, category.left, category.id)">
              <i class="bi bi-save"></i> Uložit
            </button>
            <button class="btn btn-warning btn-sm ms-2" (click)="category.addChildren = false">
              <i class="bi bi-arrow-left-circle"></i> Zpět
            </button>
          </div>
        </div>

        <!-- Pokud se nepřidává podkategorie -->
        <div *ngIf="!category.addChildren" class="d-flex align-items-center pb-1">
          <div class="me-auto">
            <span>{{ category.name }}</span>
            <span *ngIf="category.prototypeCount > 0" class="badge rounded-pill bg-secondary ms-2">Má šablony</span>
          </div>





          <!-- Animovaný kontejner pro tlačítka specifické pro danou kategorii -->
          <div [@slideIn]="isButtonsVisible(category.id) ? 'visible' : 'hidden'" class="btn-group btn-group-sm" role="group">
            <button class="btn btn-primary" (click)="category.addChildren = true">
              <i class="bi bi-plus-circle"></i> Přidat podkategorii
            </button>
            <button class="btn btn-secondary" (click)="addTransactionToCategory(category)">
              <i class="bi bi-list"></i> Zobrazit transakce
            </button>
            <button class="btn btn-secondary" (click)="addTemplateToCategory(category)">
              <i class="bi bi-file-earmark-plus"></i> Přidat šablonu
            </button>
            <button *ngIf="category.prototypeCount > 0" class="btn btn-secondary" (click)="showTemplates(category)">
              <i class="bi bi-folder"></i> Zobrazit šablony
            </button>
            <button class="btn btn-danger" (click)="deleteCategory(category.id)">
              <i class="bi bi-trash"></i> Smazat
            </button>
          </div>

          <!-- Tlačítko pro zobrazení/skrytí tlačítek specifické pro danou kategorii -->
          <button class="btn btn-link text-white" (click)="toggleButtonsVisible(category.id)">
            <i [ngClass]="isButtonsVisible(category.id) ? 'bi bi-chevron-left' : 'bi bi-chevron-down'"></i>
          </button>

        </div>

        <!-- Rekurzivní vykreslení podkategorií -->
        <ul *ngIf="category.children?.length > 0" class="ms-4">
          <ng-container *ngTemplateOutlet="treeView; context:{ $implicit: category.children, root: false }"></ng-container>
        </ul>
      </li>
    </ng-template>

    <!-- První spuštění komponenty -->
    <ng-container *ngTemplateOutlet="treeView; context:{ $implicit: financeCategories, root: true }"></ng-container>
  </ul>
</div>
