import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID, APP_INITIALIZER } from '@angular/core';

import { registerLocaleData } from '@angular/common';
import localeCs from '@angular/common/locales/cs';

import { AppComponent } from './app.component';
import { ConfigService } from './services/config.service';
import { HttpClient, HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Observable, ObservableInput, of } from 'rxjs';
import { routing } from 'src/app.routing';
import { JwtInterceptor } from './_helper/jwtTokenInterceptor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { TopNavbarComponent } from './shared/top-navbar/top-navbar.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { TaskMainComponent } from './task/task-main/task-main.component';
import { DummyContentComponent } from './temp/dummy-content/dummy-content.component';
import { TopNavService } from './services-local/top-nav-service';
import { ArticleMainComponent } from './article/article-main/article-main.component';
import { ArticleDetailComponent } from './article/article-detail/article-detail.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { FinanceMainComponent } from './finance/finance-main/finance-main.component';
import { TreeViewComponent } from './finance/tree-view/tree-view.component';
import { FinanceRecurringTypeComponent } from './finance/finance-recurring-type/finance-recurring-type.component';
import { FinanceTransactionTemplateComponent } from './finance/finance-transaction-template/finance-transaction-template.component';
import { FinanceTransactionComponent } from './finance/finance-transaction/finance-transaction.component';
import { TimeframeFilterComponent } from './shared/timeframe-filter/timeframe-filter.component';
import { FinanceTransactionDetailComponent } from './finance/finance-transaction-detail/finance-transaction-detail.component';
import { FinanceTransactionTemplateDetailComponent } from './finance/finance-transaction-template-detail/finance-transaction-template-detail.component';
import { FinanceOverviewComponent } from './finance/finance-overview/finance-overview.component';
import { FinanceLoansDebtsComponent } from './finance/finance-loans-debts/finance-loans-debts.component';


registerLocaleData(localeCs, 'cs');

export function loadConfiguration(http: HttpClient, config: ConfigService): (() => Promise<boolean>) {
  return (): Promise<boolean> => {
    return new Promise<boolean>((resolve: (a: boolean) => void): void => {
      http.get('./config.json')
        .pipe(
          map((x: ConfigService) => {
            config.baseUrl = x.baseUrl;
            resolve(true);
          }),
          catchError((x: { status: number }, caught: Observable<void>): ObservableInput<{}> => {
            if (x.status !== 404) {
              resolve(false);
            }
            // config.baseUrl = 'https://dev.pandabundacom.com/';
            config.baseUrl = 'http://localhost:4200/';
            resolve(true);
            return of({});
          })
        ).subscribe();
    });
  };
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    TopNavbarComponent,
    SidebarComponent,
    TaskMainComponent,
    DummyContentComponent,
    ArticleMainComponent,
    ArticleDetailComponent,
    FinanceMainComponent,
    TreeViewComponent,
    FinanceRecurringTypeComponent,
    FinanceTransactionTemplateComponent,
    FinanceTransactionComponent,
    TimeframeFilterComponent,
    FinanceTransactionDetailComponent,
    FinanceTransactionTemplateDetailComponent,
    FinanceOverviewComponent,
    FinanceLoansDebtsComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    EditorModule,
    routing,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'cs' },
    {
      provide: APP_INITIALIZER,
      useFactory: loadConfiguration,
      multi: true,
      deps: [HttpClient, ConfigService]
    },
    TopNavService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
