import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FinanceTransactionPrototype } from 'src/app/model/finance-transaction-prototype.model';
import { FinanceTransaction } from 'src/app/model/finance-transaction.model';
import { TopNavService } from 'src/app/services-local/top-nav-service';
import { FinanceCategoryService } from 'src/app/services/finance-category.service';
import { FinanceTransactionService } from 'src/app/services/finance-transaction.service';
import { FinanceTransactionPrototypeService } from 'src/app/services/finance-transactionPrototype.service';
import { FinanceRecurringTypeService } from 'src/app/services/finance-transactionRecurringType.service';
import { FinanceMenuItems } from '../FinanceMenuItems';

@Component({
  selector: 'app-finance-transaction-template-detail',
  templateUrl: './finance-transaction-template-detail.component.html',
  styleUrls: ['./finance-transaction-template-detail.component.scss']
})
export class FinanceTransactionTemplateDetailComponent {

  constructor(private topNavService: TopNavService,
    private financeTransactionProtoypeService: FinanceTransactionPrototypeService,
    private financeCategoryService: FinanceCategoryService,
    private financeRecurringTypeService: FinanceRecurringTypeService,
    private financeTransactionService: FinanceTransactionService,
    private router: Router,
    //   const state = this.router.getCurrentNavigation().extras.state;
    //   if (!state) {
    //     this.mode = 'default';
    //     this.updateControls();
    //     return;
    //   }
    //   this.mode = state['mode'];
    //   this.financeCategory = state['category'];
    //   this.updateControls();
    // };
    private route: ActivatedRoute){
      this.detailId = parseInt(this.route.snapshot.paramMap.get('id'));
  }


  financeTransactions: FinanceTransaction[];

// financeCategories: FinanceCategory[] = [];
// recurringTypes: FinanceRecurringType[] = [];
// financeTransactionPrototypes: FinanceTransactionPrototype[];
//financeCategory: FinanceCategory;

  //mode:String;

  detailId: number;
  detail: FinanceTransactionPrototype;



  ngOnInit(): void {
    this.topNavService.setNewContent(FinanceMenuItems.NAVIGATION_ITEMS);
    this.loadData();

    // if ( this.mode != 'default') {

    // }
  }

  private loadData() {
    this.financeTransactionProtoypeService.getById(this.detailId).subscribe(detail => {
      this.detail = detail;
    });

    this.financeTransactionService.getByPrototype(this.detailId).subscribe((e) => {
      this.financeTransactions = e;
    });
  }

  private updateControls() {
    // if (this.mode == 'add') {

    // }
    // if (this.mode == 'show') {

    // }
    // if (this.mode == 'default') {

    // }
  }

  generateNext(count:number) {
    this.financeTransactionService.generateFromPrototype(this.detailId,count).subscribe( e => {
      this.loadData();
    });
  }


  goToTransactionDetail(transaction: FinanceTransaction)  {
    this.router.navigate(['finance/transaction-detail',transaction.id]);
  }

  onDelete(id: number) {
    this.financeTransactionService
      .delete(id)
      .subscribe((e) => this.loadData());
  }


}
