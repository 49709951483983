import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import { Observable } from 'rxjs';
import { FinanceRecurringType } from '../model/finance-recurring-type.model';
import { FinanceTransactionPrototype } from '../model/finance-transaction-prototype.model';

@Injectable({providedIn: 'root'})
export class FinanceTransactionPrototypeService {

  constructor(private http: HttpClient, private config: ConfigService) { }


  getAll(): Observable<FinanceTransactionPrototype[]> {
    return this.http.get<FinanceTransactionPrototype[]>(this.config.baseUrl + 'api/finance/transactionPrototype/all');
  }

  getById(id: number): Observable<FinanceTransactionPrototype> {
    return this.http.get<FinanceTransactionPrototype>(this.config.baseUrl + `api/finance/transactionPrototype/${id}`);
  }

  getByFinanceCategoryId(id: number): Observable<FinanceTransactionPrototype[]> {
    return this.http.get<FinanceTransactionPrototype[]>(this.config.baseUrl + `api/finance/transactionPrototype/byCategoryId/${id}`);
  }

  create(transactionPrototype: FinanceTransactionPrototype): Observable<any> {
   return this.http.post(this.config.baseUrl + 'api/finance/transactionPrototype/', transactionPrototype);
  }

  save(transactionPrototype: FinanceTransactionPrototype): Observable<any> {
    return this.http.put(this.config.baseUrl + `api/finance/transactionPrototype/${transactionPrototype.id}`, transactionPrototype);
   }

  delete(id: number): Observable<any> {
    return this.http.delete(this.config.baseUrl + `api/finance/transactionPrototype/${id}`);
  }

}
