<div class="container my-5">

  <h2> Detail transakce id {{ detail?.id }} </h2>

  <p>Název: {{ detail?.name}}</p>
  <p>Kategorie: {{ detail?.categoryName }}</p>
  <ng-container *ngIf="detail?.amountSave == null || detail?.amountSave == 0">
    <P>Splatnost: {{ detail?.dueDate | date : 'd.M.yy'}}</P>
    <p>Má dáti: {{ detail?.amountShouldGive}}</p>
    <p>Dal: {{ detail?.amountGave}}</p>
    <p>Zbýva: {{ detail?.amountShouldGive - detail?.amountGave }} </p>
    <p>Poznámka: {{ detail?.note }} </p>
    <div class="btn btn-primary" (click)="toggleEdit()">Upravit</div>

    <div class="btn btn-success ms-2" (click)="totalPay()">Zaplatit</div>
    <div class="ms-2 btn btn-secondary" (click)="toglePartialPayment()">Zaplatit částečně</div>
    <div *ngIf="partialPayment" class="mt-2">
      <input type="number" #partialPayInput>
      <div class="btn btn-success ms-2" (click)="partialPay(+ partialPayInput.value)">Uložit</div>
    </div>
  </ng-container>
  <ng-container *ngIf="detail?.amountSave > 0">
    <p>Odložit: {{ detail?.amountSave }} Kč</p>
    <p>Poznámka: {{ detail?.note }} </p>
  </ng-container>

  <ng-container *ngIf="isEditing">
    <div class="bg-primary container p-4 rounded-3 border border-success my-3">
      <div>
        <label for="name">Název:</label>
        <input id="name" [(ngModel)]="detail.name" class="form-control" />
      </div>
      <div>
        <label for="category">Kategorie: {{ detail.categoryName }}</label>
        <input id="category" [(ngModel)]="detail.categoryName" readonly disabled class="form-control bg-dark" />
      </div>
      <div>
        <label for="dueDate">Splatnost:</label>
        <input id="dueDate" type="date" [(ngModel)]="detail.dueDate" class="form-control" />
      </div>
      <div>
        <label for="amountShouldGive">Má dáti:</label>
        <input id="amountShouldGive" type="number" [(ngModel)]="detail.amountShouldGive" class="form-control" />
      </div>
      <div>
        <label for="amountGave">Dal:</label>
        <input id="amountGave" type="number" [(ngModel)]="detail.amountGave" class="form-control" />
      </div>
      <div>
        <label for="note">Poznámka:</label>
        <textarea id="note" [(ngModel)]="detail.note" class="form-control"></textarea>
      </div>
      <div class="btn btn-success mt-2" (click)="saveChanges()">Uložit změny</div>
      <div class="btn btn-secondary mt-2 ms-2" (click)="cancelEdit()">Zrušit</div>
    </div>
  </ng-container>


</div>