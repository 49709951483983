<div class="container my-5">

  <h2 *ngIf="mode == 'default'">Seznam šablon plateb</h2>
  <p *ngIf="mode == 'add'">Přidání šablony do kategorie {{ financeCategory.name }}</p>
  <p *ngIf="mode == 'show'">Seznam šablon v kategorii {{ financeCategory.name }}</p>

  <ng-container *ngIf="showList">

    <table class="table table-dark table-striped table-hover mt-4">
      <thead>
        <tr>
          <th scope="col">Název</th>
          <th scope="col">Kategorie</th>
          <th scope="col">Částka</th>
          <th scope="col">Opakování</th>
          <th scope="col">Akce</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let financeTransactionPrototype of financeTransactionPrototypes">
          <td>{{ financeTransactionPrototype.name }}</td>
          <td>{{ financeTransactionPrototype.financeCategory.fullName }}</td>
          <td>{{ financeTransactionPrototype.amount }}</td>
          <td>{{ financeTransactionPrototype.recurringType.name }}</td>
          <td class="d-flex">
            <span class="ms-auto">
              <div class="btn btn-sm btn-success me-2" (click)="goToTransactionTemplateDetail(financeTransactionPrototype)">detail</div>
              <!-- <i class="bi bi-pen me-2" (click)="onEdit(financeTransaction.id)"></i>-->
              <i class="bi bi-trash text-danger" (click)="onDelete(financeTransactionPrototype.id)"></i> 
            </span>
            </td>
        </tr>
      </tbody>
    </table>

  </ng-container>

  <p>Přidat/editovat</p>
  <div *ngIf="!showEditForm" class="btn btn-success" (click)="newFinanceRecurringType()">Nová šablona</div>
  <ng-container *ngIf="showEditForm">
    <form [formGroup]="detailForm" (ngSubmit)="onSave()">

      <div class="form-group">
        <label>Název</label>
        <input type="text"  formControlName="name" class="form-control" placeholder="Název">
      </div>
      <div class="form-group">
        <label>Kategorie</label>
        <select class="form-select"(change)="changeCategory($event)" formControlName="categoryId">
          <option value="" disabled>Vyberte kategorii</option>
          <option *ngFor="let category of financeCategories" value="{{ category.id }}" [selected]="category.id == financeCategory?.id" [disabled]="mode == 'add'">
            {{ category.name  }} - {{ category.fullName }}
          </option>
        </select>
      </div>
      <div class="form-group">
        <label>Opakování</label>
        <select class="form-select"(change)="changeRecurring($event)" formControlName="recurringTypeId">
          <option value="">Vyberte opakování</option>
          <option *ngFor="let recurringType of recurringTypes" value="{{ recurringType.id }}">
            {{ recurringType.name }}
          </option>
      </select>
      </div>
      <div class="form-group">
        <label>Částka</label>
        <input type="number" formControlName="amount" class="form-control">
      </div>
      <div class="form-group">
        <label>Poznámka</label>
        <input formControlName="note" class="form-control">
      </div>

      <div class="mt-3">
        <button class="btn btn-primary" (click)="this.showEditForm = false">Zavřít</button>
        <button class="btn btn-success ms-2" type="submit" [disabled]="!detailForm.valid">Uložit</button>
      </div>

    </form>
  </ng-container>

</div>
