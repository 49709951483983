import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FinanceCategory } from 'src/app/model/finance-category.model';
import { FinanceRecurringType } from 'src/app/model/finance-recurring-type.model';
import { FinanceTransactionPrototype } from 'src/app/model/finance-transaction-prototype.model';
import { TopNavService } from 'src/app/services-local/top-nav-service';
import { FinanceCategoryService } from 'src/app/services/finance-category.service';
import { FinanceTransactionPrototypeService } from 'src/app/services/finance-transactionPrototype.service';
import { FinanceRecurringTypeService } from 'src/app/services/finance-transactionRecurringType.service';
import { FinanceMenuItems } from '../FinanceMenuItems';

@Component({
  selector: 'app-finance-transaction-template',
  templateUrl: './finance-transaction-template.component.html',
  styleUrls: ['./finance-transaction-template.component.scss']
})
export class FinanceTransactionTemplateComponent implements OnInit {

  constructor(private topNavService: TopNavService,
              private financeTransactionProtoypeService: FinanceTransactionPrototypeService,
              private financeCategoryService: FinanceCategoryService,
              private financeRecurringTypeService: FinanceRecurringTypeService,
              private router: Router){
                const state = this.router.getCurrentNavigation()?.extras.state;
                if (!state) {
                  this.mode = 'default';
                  this.updateControls();
                  return;
                }
		            this.mode = state['mode'];
                this.financeCategory = state['category'];
                this.updateControls();
              };

  financeCategories: FinanceCategory[] = [];
  recurringTypes: FinanceRecurringType[] = [];
  financeTransactionPrototypes: FinanceTransactionPrototype[];
  financeCategory: FinanceCategory;

  showEditForm: boolean;
  showList: boolean;

  mode:String;



  ngOnInit(): void {
    this.topNavService.setNewContent(FinanceMenuItems.NAVIGATION_ITEMS);
    this.loadData();

    if ( this.mode != 'default') {
      this.detailForm.get('categoryId').setValue(this.financeCategory.id, {
        onlySelf: true,
      });
    }
  }

  private updateControls() {
    if (this.mode == 'add') {
      this.showList = false;
      this.showEditForm = true;

    }
    if (this.mode == 'show') {
      this.showList = true;
      this.showEditForm = false;

    }
    if (this.mode == 'default') {
      this.showList = true;
      this.showEditForm = false;
    }
  }


  changeRecurring(e: any) {
    this.detailForm.get('recurringTypeId').setValue(e.target.value, {
      onlySelf: true,
    });
  }

  changeCategory(e: any) {
    this.detailForm.get('categoryId').setValue(e.target.value, {
      onlySelf: true,
    });
  }

  detailForm = new FormGroup({
     id: new FormControl<number|null>(null),
     name: new FormControl<string|null>(null, Validators.required),
     categoryId: new FormControl<number|null>(null, Validators.required),
     amount: new FormControl<number|null>(null, [Validators.min(0)]),
     note: new FormControl<string|null>(null),
     recurringTypeId: new FormControl<number|null>(null),
  });

  loadData() {
    if ( this.mode == 'default') {
      this.financeTransactionProtoypeService.getAll().subscribe( e =>  {
        this.financeTransactionPrototypes = e;
      });
    } else if (this.mode == 'show') {
      this.financeTransactionProtoypeService.getByFinanceCategoryId(this.financeCategory.id).subscribe( e =>  {
        this.financeTransactionPrototypes = e;
      });
    }

    this.financeCategoryService.getAll().subscribe( e =>  {
      this.financeCategories = e;
    });

    this.financeRecurringTypeService.getAll().subscribe( e =>  {
      this.recurringTypes = e;
    });
  }


  newFinanceRecurringType() {
    this.detailForm.reset();
    this.showEditForm = true;
  }

  goToTransactionTemplateDetail(transactionPrototype: FinanceTransactionPrototype)  {
     this.router.navigate(['finance/template-detail',transactionPrototype.id]);
   }

  onDelete(id: number) {
    this.financeTransactionProtoypeService.delete(id).subscribe(
      e => this.loadData()
     );
  }

  onEdit(id: number) {
    this.financeTransactionProtoypeService.getById(id).subscribe( e => {
      this.detailForm.setValue({id: e.id,
        name : e.name,
        amount: e.amount,
        note: e.note,
        categoryId: e.categoryId,
        recurringTypeId: e.recurringTypeId});
      this.showEditForm = true;
      }
    );
  }


  onSave() {
    let detail = this.detailForm.getRawValue();
    let objTosave : FinanceTransactionPrototype = { id: detail.id,
      name : detail.name,
      amount: detail.amount,
      note: detail.note,
      categoryId: detail.categoryId,
      recurringTypeId: detail.recurringTypeId};

    if (detail.id != null) {
       this.financeTransactionProtoypeService.save(objTosave).subscribe(
         e =>  {
          this.mode = 'default';
          this.updateControls();
          this.loadData();

         }
       );
    } else {
      this.financeTransactionProtoypeService.create(objTosave).subscribe(
        e => {
          this.mode = 'default';
          this.updateControls();
          this.loadData();
        }
      );
     }
  }

}
