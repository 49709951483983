import { EventEmitter, Injectable } from '@angular/core';
import { TopNavItem } from './top-nav-item';


@Injectable()
export class TopNavService {

  contextContent: EventEmitter<TopNavItem[]> = new EventEmitter();

  constructor() {

  }

  public setNewContent(items: TopNavItem[]): void {
    this.contextContent.emit(items);
  }

}
