<nav class="sidebar bg-primary" >
  <ul class="list-unstyled components">
      <p class="pt-5 ps-3">Hlavní menu</p>
      <li class="active py-2">
        <a routerLink="/tasks" class="py-2 px-3 link-danger fw-bold">Tásky</a>
          <ul class="collapse list-unstyled" id="homeSubmenu">
              <li>
                  <a href="#">Home 1</a>
              </li>
              <li>
                  <a href="#">Home 2</a>
              </li>
              <li>
                  <a href="#">Home 3</a>
              </li>
          </ul>
      </li>
      <li>
        <a routerLink="/articles" class="py-2 px-3 link-danger fw-bold">Članky</a>
      </li>
      <li>
        <a href="/finance" class="py-2 px-3 link-danger fw-bold">Finance</a>
          <ul class="collapse list-unstyled" id="pageSubmenu">
              <li>
                  <a href="#">Page 1</a>
              </li>
              <li>
                  <a href="#">Page 2</a>
              </li>
              <li>
                  <a href="#">Page 3</a>
              </li>
          </ul>
      </li>

      <li>
        <a routerLink="/socials" class="py-2 px-3 link-danger fw-bold">Sociálno</a>
      </li>

      <li>
        <a routerLink="/inventory" class="py-2 px-3 link-danger fw-bold">Invetář</a>
      </li>

      <li>
        <a href="#" class="py-2 px-3 link-danger fw-bold "><span style="flex: 1 0 auto;">Portfolio</span></a>
      </li>
      <li>
          <a href="#" class="py-2 px-3 link-danger fw-bold">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-badge-wc me-2" viewBox="0 0 16 16">
              <path d="M10.348 7.643c0-1.112.488-1.754 1.318-1.754.682 0 1.139.47 1.187 1.108H14v-.11c-.053-1.187-1.024-2-2.342-2-1.604 0-2.518 1.05-2.518 2.751v.747c0 1.7.905 2.73 2.518 2.73 1.314 0 2.285-.792 2.342-1.939v-.114h-1.147c-.048.615-.497 1.05-1.187 1.05-.839 0-1.318-.62-1.318-1.727v-.742zM4.457 11l1.02-4.184h.045L6.542 11h1.006L9 5.001H7.818l-.82 4.355h-.056L5.97 5.001h-.94l-.972 4.355h-.053l-.827-4.355H2L3.452 11h1.005z"/>
              <path d="M14 3a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12zM2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2z"/>
            </svg>
            <span style="flex: 1 0 auto;">S ikonou</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 24 24"><g data-name="Layer 2"><g data-name="chevron-left"><rect width="24" height="24" transform="rotate(90 12 12)" opacity="0"></rect><path d="M13.36 17a1 1 0 0 1-.72-.31l-3.86-4a1 1 0 0 1 0-1.4l4-4a1 1 0 1 1 1.42 1.42L10.9 12l3.18 3.3a1 1 0 0 1 0 1.41 1 1 0 0 1-.72.29z"></path></g></g></svg>
          </a>
      </li>
  </ul>
</nav>
