import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FinanceTransaction } from 'src/app/model/finance-transaction.model';
import { TopNavService } from 'src/app/services-local/top-nav-service';
import { FinanceTransactionService } from 'src/app/services/finance-transaction.service';
import { FinanceMenuItems } from '../FinanceMenuItems';

@Component({
  selector: 'app-finance-transaction-detail',
  templateUrl: './finance-transaction-detail.component.html',
  styleUrls: ['./finance-transaction-detail.component.scss']
})
export class FinanceTransactionDetailComponent {


  constructor(private topNavService: TopNavService,
    private financeTransactionService: FinanceTransactionService,
    private route: ActivatedRoute){
      this.detailId = parseInt(this.route.snapshot.paramMap.get('id'));
    };

  detailId: number;
  detail: FinanceTransaction;
  isEditing = false;

  partialPayment: boolean;


  ngOnInit(): void {
    this.topNavService.setNewContent(FinanceMenuItems.NAVIGATION_ITEMS);
    this.loadData();
  }



  toggleEdit() {
    this.isEditing = true;
  }

  saveChanges() {
    this.financeTransactionService.save(this.detail).subscribe(detail => {
      this.loadData();
      this.isEditing = false;
    });
  }

  cancelEdit() {
    this.isEditing = false;
    this.loadData();
  }


  private loadData() {
    this.financeTransactionService.getById(this.detailId).subscribe(detail => {
      this.detail = detail;
    });
  }

  totalPay() {
    this.financeTransactionService.totalPay(this.detailId).subscribe(detail => {
      this.partialPayment = false;
      this.loadData();
    });
  }


  partialPay(amount: number) {
    this.financeTransactionService.partialPay(this.detailId, amount).subscribe(detail => {
      this.partialPayment = false;
      this.loadData();
    });

  }

  toglePartialPayment() {
    this.partialPayment = !this.partialPayment;
  }

}
