import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FinanceDebtClaim } from '../model/finance-debtclaim.model';


@Injectable({
  providedIn: 'root'
})
export class FinanceDebtClaimService {

  private baseUrl = 'api/finance/debtclaim';

  constructor(private http: HttpClient) {}

  insert(debtClaim: FinanceDebtClaim): Observable<number> {
    return this.http.post<number>(`${this.baseUrl}`, debtClaim);
  }

  get(id: number): Observable<FinanceDebtClaim> {
    return this.http.get<FinanceDebtClaim>(`${this.baseUrl}/${id}`);
  }

  update(id: number, debtClaim: FinanceDebtClaim): Observable<void> {
    return this.http.put<void>(`${this.baseUrl}/${id}`, debtClaim);
  }

  delete(id: number): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/${id}`);
  }

  getAll(): Observable<FinanceDebtClaim[]> {
    return this.http.get<FinanceDebtClaim[]>(`${this.baseUrl}/all`);
  }
}
