
<div class="row">
  <div *ngFor="let article of articles" class="card my-4 ms-4 text-black bg-danger" style="width: 18rem;">
    <div class="card-body">
      <h5 class="card-title">{{article.name | uppercase }}</h5>
      <h6 class="card-subtitle mb-2 text-muted text-black-50">Card subtitle</h6>
      <p class="card-text">{{article.text | slice:0:50 }}</p>
    </div>
    <div class="my-2">
      <div class="float-end">
        <a [routerLink]="['/articles', article.id]"  class="card-link btn btn-primary">Otevřít</a>
      </div>
    </div>
  </div>

</div>

