export class FinanceMenuItems {
  public static readonly NAVIGATION_ITEMS = [
    { content: 'Kategorie', routerLink: 'finance/category' },
    { content: 'Transakce', routerLink: 'finance/transaction' },
    { content: 'Přehled', routerLink: 'finance/overview' },
    { content: 'Pohledávky/dluhy', routerLink: 'finance/debtsandloans' },
    { content: 'Šablony plateb', routerLink: 'finance/template' },
    { content: 'Opakování', routerLink: 'finance/recurringType' },
    { content: 'Nastavení', routerLink: 'finance/settings' },
  ];
}
