
<div class="vh-100"  style="background-color: #508bfc;">
  <div class="container h-100">
    <div class="d-flex justify-content-center align-items-center h-100">
      <div class="login-form" style="border-radius: 1rem;">
        <h3>Prihlaste se!</h3>
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
          <div class="form-group">
            <input type="text" class="form-control my-2" formControlName="username" placeholder="Login" value="" [ngClass]="{ 'is-invalid': submitted && f.username.errors }">
          </div>
          <div class="form-group">
            <input type="password" class="form-control my-2" formControlName="password" placeholder="Password" value="" [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
          </div>
          <div class="form-group" *ngIf="showTotp">
            <input type="text" class="form-control" formControlName="totp" placeholder="Token" value="" [ngClass]="{ 'is-invalid': submitted && f.totp.errors }">
          </div>
          <div class="form-group">
              <button [disabled]="loading" class="btnSubmit">Login</button>
              <img *ngIf="loading" class="pl-3" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
          </div>
        </form>
        <div *ngIf="totpImageUrl" class="justify-content-center align-items-center bg-white p-5 rounded">
          <img  src="{{ totpImageUrl }}">
        </div>
      </div>
    </div>
  </div>
</div>
