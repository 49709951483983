import { Component, OnInit } from '@angular/core';
import { TopNavItem } from 'src/app/services-local/top-nav-item';
import { TopNavService } from 'src/app/services-local/top-nav-service';

@Component({
  selector: 'app-top-navbar',
  templateUrl: './top-navbar.component.html',
  styleUrls: ['./top-navbar.component.scss']
})
export class TopNavbarComponent implements OnInit {


  navItems: TopNavItem[];

  constructor(private topNavSevice: TopNavService) { }

  ngOnInit(): void {
    this.topNavSevice.contextContent.subscribe(content => {
      this.navItems = content;
    });
  }

}
