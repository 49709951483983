
<div class="container my-5">
  <h3>Detail šablony plateb {{ detail.id }}</h3>


    <p>Id: {{ detail.id }} </p>
    <p>Název: {{ detail.name }}</p>

    <p>Kategorie: {{ detail.financeCategory?.fullName }}</p>
    <p>Opakování: {{ detail.recurringType?.name }}</p>
    <p>Částka: {{ detail.amount }} Kč</p>
    <p>Poznámka: {{ detail.note }}</p>


  <h3>Seznam nejbližšších transakcí:</h3>

  <table class="table table-dark table-striped table-hover mt-4">
    <thead>
      <tr>
        <th scope="col">Název</th>
        <th scope="col">Splatnost</th>
        <th scope="col">Má dáti</th>
        <th scope="col">Zbývá</th>
        <th scope="col">Akce</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let financeTransaction of financeTransactions">
        <td>{{ financeTransaction.name}}</td>
        <td>{{ financeTransaction.dueDate | date : 'd.M.yy'}}</td>
        <td>{{ financeTransaction.amountShouldGive}}</td>
        <td>{{ financeTransaction.amountShouldGive - financeTransaction.amountGave }}</td>
        <td class="d-flex"><span class="ms-auto"><div class="btn btn-sm btn-success me-2" (click)="goToTransactionDetail(financeTransaction)">detail</div><i class="bi bi-pen text-pirmary me-2"></i> <i class="bi bi-trash text-danger" (click)="onDelete(financeTransaction.id)"></i></span></td>
      </tr>
    </tbody>
  </table>


  <div class="btn btn-success" (click)="generateNext(1)">Přidat</div>
  <div class="btn btn-success ms-2" (click)="generateNext(5)">Přidat 5</div>

</div>
