import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FinanceRecurringType } from 'src/app/model/finance-recurring-type.model';
import { TopNavService } from 'src/app/services-local/top-nav-service';
import { FinanceRecurringTypeService } from 'src/app/services/finance-transactionRecurringType.service';
import { FinanceMenuItems } from '../FinanceMenuItems';

@Component({
  selector: 'app-finance-recurring-type',
  templateUrl: './finance-recurring-type.component.html',
  styleUrls: ['./finance-recurring-type.component.scss']
})
export class FinanceRecurringTypeComponent implements OnInit {

  months: string[] = ['leden', 'únor', 'březen', 'duben', 'květen', 'červen', 'červenec', 'srpen', 'září', 'říjen', 'listopad', 'prosinec'];
  financeRecurringTypes: FinanceRecurringType[] = [];
  showEditForm: boolean;

  monthsCheckMap: boolean[] = [false,false,false,false,false,false,false,false,false,false,false,false];

  constructor(private topNavService: TopNavService, private financeRecurringTypeService: FinanceRecurringTypeService){}

  ngOnInit(): void {
    this.topNavService.setNewContent(FinanceMenuItems.NAVIGATION_ITEMS);
    this.loadData();
  }

  detailForm = new FormGroup({
    id: new FormControl<number|null>(null),
    name: new FormControl('', Validators.required),
    dayDue: new FormControl<number|null>(null, [Validators.min(1), Validators.max(31)]),
    monthsBitMap: new FormControl<number|null>(null),

  });

  loadData() {
    this.financeRecurringTypeService.getAll().subscribe( e =>  {
      this.financeRecurringTypes = e;
    });
  }

  newFinanceRecurringType() {
    this.detailForm.reset();
    this.showEditForm = true;
  }

  onDelete(id: number) {
    this.financeRecurringTypeService.delete(id).subscribe(
      e => this.loadData()
     );
  }

  onEdit(id: number) {
    this.financeRecurringTypeService.getById(id).subscribe( e => {
      this.detailForm.setValue(e);
      this.refreshMonthsCheckMap(e.monthsBitMap);
      this.showEditForm = true;
      }
    );
  }


  onSave() {
    let detail = this.detailForm.getRawValue();


    var monthsBitMap = 0;
    for (var i=0; i<12;i++) {
      if (this.monthsCheckMap[i]) {
        var bitmask = Math.pow(2, i);
        monthsBitMap =  monthsBitMap | bitmask;
      }
    }
    detail.monthsBitMap = monthsBitMap;


    if (detail.id != null) {
      this.financeRecurringTypeService.save(detail).subscribe(
        e =>  {
          this.loadData();
          this.showEditForm = false;
        }
      );
    } else {
      this.financeRecurringTypeService.create(detail).subscribe(
        e => {
          this.loadData();
          this.showEditForm = false;
        }
      );
    }
  }

  refreshMonthsCheckMap(bitmap:number) {
    var monthsBitMap = [];
    for (var i=0; i<12;i++) {
      var bitmask = Math.pow(2, i);
      monthsBitMap[i]=  (bitmap & bitmask) > 0;
    }
    this.monthsCheckMap = monthsBitMap;

  }

  selectAllMonths() {
    this.monthsCheckMap = [true,true,true,true,true,true,true,true,true,true,true,true];
  }

  deSelectAllMonths() {
    this.monthsCheckMap = [false,false,false,false,false,false,false,false,false,false,false,false];
  }

  onMonthToggle(evt, idx: number) {
    this.monthsCheckMap[idx] = evt.srcElement.checked;
  }

}
