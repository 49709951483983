import { Component, OnInit } from '@angular/core';
import { Task } from 'src/app/model/task.model';
import { TopNavService } from 'src/app/services-local/top-nav-service';
import { TaskService } from 'src/app/services/task.service';
import { TaskContainer } from '../model/task-container';

@Component({
  selector: 'app-task-main',
  templateUrl: './task-main.component.html',
  styleUrls: ['./task-main.component.scss']
})
export class TaskMainComponent implements OnInit {

  tasks: TaskContainer[] = [{
    name: 'Ze serveru',
    tasks: [],
    completed: 0,
    total: 0

  }
];

  detail: Task = null;

  constructor(private topNavService: TopNavService, private taskService: TaskService) { }

  ngOnInit(): void {
    this.topNavService.setNewContent([{content: "TBD", routerLink: "tasks"}]);
    this.loadData();
  }

  loadData(): void {

    this.taskService.getAll().subscribe(taskList => {
      this.tasks[0].tasks = taskList;
      this.tasks[0].total = taskList.length;

    });
  }


  newTask(): void {
    if (this.detail == null) {
      this.detail = {};
    } else {
      this.taskService.create({name: this.detail.name}).subscribe(e =>{
        this.loadData();
        this.detail = null;
      })
    }
  }

}
